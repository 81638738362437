import request from '@/utils/request'

// 查询油卡账务记录列表
export function listOilAccountRecord(query) {
  return request({
    url: '/iot/oilAccountRecord/list',
    method: 'get',
    params: query
  })
}

// 查询油卡账务记录详细
export function getOilAccountRecord(id) {
  return request({
    url: '/iot/oilAccountRecord/' + id,
    method: 'get'
  })
}

// 新增油卡账务记录
export function addOilAccountRecord(data) {
  return request({
    url: '/iot/oilAccountRecord',
    method: 'post',
    data: data
  })
}

// 修改油卡账务记录
export function updateOilAccountRecord(data) {
  return request({
    url: '/iot/oilAccountRecord',
    method: 'put',
    data: data
  })
}

// 删除油卡账务记录
export function delOilAccountRecord(id) {
  return request({
    url: '/iot/oilAccountRecord/' + id,
    method: 'delete'
  })
}

// 导出油卡账务记录
export function exportOilAccountRecord(query) {
  return request({
    url: '/iot/oilAccountRecord/export',
    method: 'get',
    params: query
  })
}

// 核销油卡账务记录
export function cancelVerification(data) {
  return request({
    url: '/iot/oilAccountRecord/cancelVerification',
    method: 'post',
    data: data
  })
}

// 划拨油卡账务记录
export function transfer(data) {
  return request({
    url: '/iot/oilAccountRecord/transfer',
    method: 'post',
    data: data
  })
}
