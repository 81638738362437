<template>
  <vxe-modal v-model="open" width="50%" height="100%" :title="formTitle" :show-zoom="true" resize :lock-view="false" :mask="true" @close="onClose">
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="充值卡号" prop="cardNo">
        <!-- <a-input v-model="form.cardNo" placeholder="请输入加油卡卡号" /> -->
        <a-select
          show-search
          placeholder="请输入充值卡号"
          :default-active-first-option="false"
          :show-arrow="false"
          :filter-option="false"
          :not-found-content="null"
          @search="handleMainCardSearch"
          @change="handleMainCardChange"
          :loading="loading"
          :disabled="isOnlyView"
          v-model="form.cardNo"
        >
          <a-select-option v-for="(d, index) in cardNoArray" :key="index">{{ d.cardNo }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <!-- <a-form-model-item label="账务类型" prop="accountType">
        <a-select placeholder="请选择账务类型" v-model="form.accountType">
          <a-select-option value>请选择字典生成</a-select-option>
        </a-select>
      </a-form-model-item> -->
      <a-form-model-item label="此卡当前总余额（元）" prop="balanceMainCard">
        <a-input v-model="form.balanceMainCard" disabled placeholder="此卡当前总余额（元）" />
      </a-form-model-item>
      <a-form-model-item label="此卡当前未划拨余额（元）" prop="balanceAvailable">
        <a-input v-model="form.balanceAvailable" disabled placeholder="此卡当前未划拨余额（元）" />
      </a-form-model-item>
      <a-form-model-item label="充值发起人" prop="createBy">
        <a-input v-model="form.createBy" disabled placeholder="充值发起人" />
      </a-form-model-item>
      <a-form-model-item label="发起时间" prop="createTime">
        <a-input v-model="form.createTime" disabled placeholder="发起时间" />
      </a-form-model-item>
      <a-form-model-item label="充值金额" prop="amount">
        <a-input v-model="form.amount" :disabled="isOnlyView" placeholder="请输入充值金额" />
      </a-form-model-item>
      <a-form-model-item label="上传单据" prop="photo">
        <!-- 注意手动设置初始值：initFileList，数据单向赋值 -->
        <many-file-upload
          :init-file-list="form.initFileList"
          folder="recharge"
          type="image"
          :maxFileCount="15"
          @onUploading="uploading = $event"
          @billImageNum="form.billImageNum = $event"
          @fileList="
            form.billImageUrl = $event && $event.length > 0 ? $event.map((p) => p.url) : []
            form.billImage = $event && $event.length > 0 ? $event.map((p) => p.fileName).join(',') : ''
          "
          @
        ></many-file-upload>
      </a-form-model-item>

      <a-form-model-item label="备注说明" prop="comment">
        <a-input v-model="form.comment" :disabled="isOnlyView" placeholder="请输入备注说明" />
      </a-form-model-item>
      <div class="bottom-control" v-if="!isOnlyView">
        <a-space>
          <a-button type="primary" :loading="loading || uploading" @click="submitForm"> {{ uploading ? '上传中' : '保存' }}</a-button>
          <a-button type="dashed" @click="cancel">取消</a-button>
        </a-space>
      </div>
    </a-form-model>
  </vxe-modal>
</template>

<script>
import { getOilAccountRecord, addOilAccountRecord, updateOilAccountRecord } from '@/api/iot/oilAccountRecord'
import { mainCardList } from '@/api/iot/oilCard'
import { mapState } from 'vuex'
import debounce from 'lodash/debounce'
import ManyFileUpload from '@/components/ManyFileUpload'
import base from '../../../../base'
export default {
  mixins: [base],
  name: 'RechargeForm',
  props: {
    source: {
      type: String,
      default: ''
    },
    isShow: {
      type: Boolean,
      default: false
    },
    approvalRecord: {
      type: Object,
      default: null
    },
    action: {
      type: String,
      default: ''
    }
  },
  components: { ManyFileUpload },
  data() {
    this.handleMainCardSearch = debounce(this.handleMainCardSearch, 500)
    return {
      loading: false,
      uploading: false,
      isOnlyView: false,
      formTitle: '充值申请',
      // 表单参数
      form: {
        cardNo: null,
        accountType: 'recharge',
        amount: null,
        amountTransfer: null,
        balanceMainCard: 0,
        balanceBranchCard: 0,
        balanceAvailable: 0,
        inchargeBy: null,
        comment: null,
        approvalStatus: null,
        createTime: null,
        createBy: null,
        procdefKey: null,
        template: null,
        billImageUrl: null,
        billImage: null,
        initFileList: []
      },
      // 1增加,2修改
      formType: 1,
      open: this.isShow,
      rules: {
        cardNo: [{ required: true, message: '充值卡号不能为空', trigger: 'blur' }],
        amount: [{ required: true, message: '充值金额不能为空', trigger: 'change' }]
      },
      cardNoArray: []
    }
  },
  filters: {},
  created() {
    console.log('rechargeForm', 'created')
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.info
    })
  },
  watch: {},
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      if (this.action === 'add') {
        this.handleAdd()
      } else {
        this.handleUpdate(this.approvalRecord)
      }
    },
    onClose() {
      this.open = false
      this.$emit('componentClose')
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
      this.$emit('componentClose')
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.isOnlyView = false
      this.form = {
        cardNo: null,
        accountType: 'recharge',
        amount: null,
        amountTransfer: null,
        balanceMainCard: null,
        balanceBranchCard: null,
        inchargeBy: null,
        comment: null,
        approvalStatus: null,
        createTime: null,
        createBy: null,
        initFileList: []
      }
    },
    /** 新增按钮操作 */
    handleAdd(title, record) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '充值申请'
      this.form.procdefKey = this.approvalRecord.approvalKind
      this.form.template = this.approvalRecord.template
      this.initForm(record)
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row.approvalKindId
      getOilAccountRecord(id).then((response) => {
        this.form = response.data
        const form = { ...this.form }
        this.form.initFileList = { billImage: form.billImage, billImageUrl: form.billImageUrl }
        this.open = true
        this.formTitle = '充值申请--修改'
      })
    },
    initForm(record) {
      console.log('userInfo1', this.userInfo)
      if (record) {
        this.isOnlyView = true
        Object.assign(this.form, record)
        return
      }
      this.isOnlyView = false
      this.form.createBy = this.userInfo.userName
      this.form.inchargeBy = this.userInfo.userId
      const d = new Date()
      const Y = d.getFullYear()
      const M = d.getMonth() + 1
      const D = d.getDate()
      const h = d.getHours()
      const m = d.getMinutes()
      const s = d.getSeconds()
      const time = Y + '-' + M + '-' + D + ' ' + h + ':' + m + ':' + s
      this.form.createTime = time
      console.log('this.form', this.form)
      this.$forceUpdate()
    },
    /** 提交按钮 */
    submitForm: function () {
      if (!this.doCheckBeforeSave()) {
        return
      }
      this.doSave()
    },
    doSave() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.form.mainCardNo = this.form.cardNo
          this.loading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateOilAccountRecord(this.form)
              .then((response) => {
                this.loading = false
                this.$message.success('修改成功', 3)
                this.open = false
                this.$emit('ok')
              })
              .catch(() => {
                this.loading = false
              })
          } else {
            this.form.mainCardNo = this.form.cardNo
            addOilAccountRecord(this.form)
              .then((response) => {
                this.loading = false
                this.$message.success('新增成功', 3)
                this.open = false
                this.$emit('ok')
              })
              .catch(() => {
                this.loading = false
              })
          }
        } else {
          return false
        }
      })
    },
    handleMainCardSearch(value) {
      console.log('handleMainCardSearch', value)
      const queryParam = {
        mainCardNo: value,
        pageNum: 1,
        pageSize: 10
      }
      this.loading = true
      mainCardList(queryParam).then((response) => {
        this.cardNoArray = response
        this.loading = false
      })
    },
    handleMainCardChange(value) {
      console.log('handleMainCardChange', value)
      this.form.cardNo = this.cardNoArray[value].cardNo
      const tmpArr = this.cardNoArray.filter((res) => res.cardNo === this.form.cardNo)
      console.log('tmpArr', tmpArr)
      if (tmpArr.length > 0) {
        this.form.balanceMainCard = tmpArr[0].balanceTotal
        this.form.balanceAvailable = tmpArr[0].balanceAvailable
      }
      console.log('this.form2', this.form)
    }
  }
}
</script>
