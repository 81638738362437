var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vxe-modal",
    {
      attrs: {
        width: "50%",
        height: "100%",
        title: _vm.formTitle,
        "show-zoom": true,
        resize: "",
        "lock-view": false,
        mask: true,
      },
      on: { close: _vm.onClose },
      model: {
        value: _vm.open,
        callback: function ($$v) {
          _vm.open = $$v
        },
        expression: "open",
      },
    },
    [
      _c(
        "a-form-model",
        { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "充值卡号", prop: "cardNo" } },
            [
              _c(
                "a-select",
                {
                  attrs: {
                    "show-search": "",
                    placeholder: "请输入充值卡号",
                    "default-active-first-option": false,
                    "show-arrow": false,
                    "filter-option": false,
                    "not-found-content": null,
                    loading: _vm.loading,
                    disabled: _vm.isOnlyView,
                  },
                  on: {
                    search: _vm.handleMainCardSearch,
                    change: _vm.handleMainCardChange,
                  },
                  model: {
                    value: _vm.form.cardNo,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "cardNo", $$v)
                    },
                    expression: "form.cardNo",
                  },
                },
                _vm._l(_vm.cardNoArray, function (d, index) {
                  return _c("a-select-option", { key: index }, [
                    _vm._v(_vm._s(d.cardNo)),
                  ])
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: { label: "此卡当前总余额（元）", prop: "balanceMainCard" },
            },
            [
              _c("a-input", {
                attrs: { disabled: "", placeholder: "此卡当前总余额（元）" },
                model: {
                  value: _vm.form.balanceMainCard,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "balanceMainCard", $$v)
                  },
                  expression: "form.balanceMainCard",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: "此卡当前未划拨余额（元）",
                prop: "balanceAvailable",
              },
            },
            [
              _c("a-input", {
                attrs: {
                  disabled: "",
                  placeholder: "此卡当前未划拨余额（元）",
                },
                model: {
                  value: _vm.form.balanceAvailable,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "balanceAvailable", $$v)
                  },
                  expression: "form.balanceAvailable",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "充值发起人", prop: "createBy" } },
            [
              _c("a-input", {
                attrs: { disabled: "", placeholder: "充值发起人" },
                model: {
                  value: _vm.form.createBy,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "createBy", $$v)
                  },
                  expression: "form.createBy",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "发起时间", prop: "createTime" } },
            [
              _c("a-input", {
                attrs: { disabled: "", placeholder: "发起时间" },
                model: {
                  value: _vm.form.createTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "createTime", $$v)
                  },
                  expression: "form.createTime",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "充值金额", prop: "amount" } },
            [
              _c("a-input", {
                attrs: {
                  disabled: _vm.isOnlyView,
                  placeholder: "请输入充值金额",
                },
                model: {
                  value: _vm.form.amount,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "amount", $$v)
                  },
                  expression: "form.amount",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "上传单据", prop: "photo" } },
            [
              _c("many-file-upload", {
                attrs: {
                  "init-file-list": _vm.form.initFileList,
                  folder: "recharge",
                  type: "image",
                  maxFileCount: 15,
                },
                on: {
                  onUploading: function ($event) {
                    _vm.uploading = $event
                  },
                  billImageNum: function ($event) {
                    _vm.form.billImageNum = $event
                  },
                  fileList: function ($event) {
                    _vm.form.billImageUrl =
                      $event && $event.length > 0
                        ? $event.map(function (p) {
                            return p.url
                          })
                        : []
                    _vm.form.billImage =
                      $event && $event.length > 0
                        ? $event
                            .map(function (p) {
                              return p.fileName
                            })
                            .join(",")
                        : ""
                  },
                  "": function ($event) {},
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "备注说明", prop: "comment" } },
            [
              _c("a-input", {
                attrs: {
                  disabled: _vm.isOnlyView,
                  placeholder: "请输入备注说明",
                },
                model: {
                  value: _vm.form.comment,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "comment", $$v)
                  },
                  expression: "form.comment",
                },
              }),
            ],
            1
          ),
          !_vm.isOnlyView
            ? _c(
                "div",
                { staticClass: "bottom-control" },
                [
                  _c(
                    "a-space",
                    [
                      _c(
                        "a-button",
                        {
                          attrs: {
                            type: "primary",
                            loading: _vm.loading || _vm.uploading,
                          },
                          on: { click: _vm.submitForm },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.uploading ? "上传中" : "保存")
                          ),
                        ]
                      ),
                      _c(
                        "a-button",
                        {
                          attrs: { type: "dashed" },
                          on: { click: _vm.cancel },
                        },
                        [_vm._v("取消")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }